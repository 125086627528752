import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class ChatService {
  private websocket: any;

  message$ = new BehaviorSubject(null);
  newMessage = this.message$.asObservable();

  constructor() {
    this.initializeWebSocket();
  }

  sendMessage(message: string) {
    this.websocket.send(message);
  }

  private initializeWebSocket() {
    this.websocket = new WebSocket(environment.chatWebSocketURL);

    this.websocket.onopen = (event) => {
      console.log(event);
    };

    this.websocket.onerror = (event) => {
      console.log(event);
    };

    this.websocket.onclose = (event) => {
      this.initializeWebSocket();
    };

    this.websocket.onmessage = (event) => {
      this.message$.next(JSON.parse(event.data));
    };
  }
}
