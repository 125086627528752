import { Component, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { ChatService } from '../core/chat.service';

declare var $;

@Component({
  selector: 'app-chat-admin',
  templateUrl: './chat-admin.component.html',
  styleUrls: ['./chat-admin.component.scss'],
})
export class ChatAdminComponent implements OnInit {
  messages = [];

  clients = [];

  adminMsg = new FormControl('');

  selectedTabIndex = null;
  domain: string;
  constructor(private ChatService: ChatService) {}

  ngOnInit(): void {
    this.domain = window.location.hostname;
    this.ChatService.newMessage.subscribe((data: any) => {
      if (
        data &&
        data.type !== 'system' &&
        data.name !== 'admin' &&
        data.message.domain === this.domain
      ) {
        if (this.clients.indexOf(data.name) === -1) {
          this.clients.push(data.name);
          this.selectedTabIndex = this.clients.length - 1;
        } else {
          this.selectedTabIndex = this.clients.indexOf(data.name);
        }

        this.messages.push({
          type: data.type,
          name: data.name,
          message: {
            text: data.message.text,
            recipient: data.message.recipient,
          },
        });

        $(`#chat__history-${this.selectedTabIndex}`).animate(
          {
            scrollTop: 999999,
          },
          800
        );
      }
    });
  }

  keyup(event) {
    if (event.code === 'Enter') {
      event.preventDefault();
      this.sendMessage();
    }
  }

  sendMessage() {
    if (this.adminMsg.value) {
      const message = {
        message: {
          text: this.adminMsg.value,
          recipient: this.clients[this.selectedTabIndex],
        },
        name: 'admin',
      };

      this.ChatService.sendMessage(JSON.stringify(message));

      this.messages.push({
        type: 'usermsg',
        name: 'admin',
        message: {
          text: this.adminMsg.value,
          recipient: this.clients[this.selectedTabIndex],
        },
      });

      this.adminMsg.setValue('');
    }

    $(`#chat__history-${this.selectedTabIndex}`).animate(
      {
        scrollTop: 999999,
      },
      800
    );
  }

  sendSurvey() {
    const message = {
      message: {
        text: '$survey',
        recipient: this.clients[this.selectedTabIndex],
      },
      name: 'admin',
    };

    this.ChatService.sendMessage(JSON.stringify(message));

    this.messages.push({
      type: 'usermsg',
      name: 'admin',
      message: {
        text: '(SURVEY SENT)',
        recipient: this.clients[this.selectedTabIndex],
      },
    });

    $(`#chat__history-${this.selectedTabIndex}`).animate(
      {
        scrollTop: 999999,
      },
      800
    );
  }
}
