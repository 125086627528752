import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';
import { SurveyApiService } from './survey-api.service';

@Component({
  selector: 'app-core',
  templateUrl: './core.component.html',
  styleUrls: ['./core.component.scss'],
})
export class CoreComponent implements OnInit, OnDestroy {
  subscription$: Subscription;

  constructor(
    // private ActivatedRoute: ActivatedRoute,
    private SurveyApiService: SurveyApiService
  ) {}

  ngOnInit(): void {
    // this.subscription$ = this.ActivatedRoute.data.subscribe((response: any) => {
    //   this.SurveyApiService.apiToken = response.surveyAPITokenResolver.token;
    // });
    this.subscription$ = this.SurveyApiService.loginToAPI().subscribe(
      (response: any) => {
        this.SurveyApiService.apiToken$.next(response.token);
      }
    );
  }

  ngOnDestroy(): void {
    this.subscription$.unsubscribe();
  }
}
